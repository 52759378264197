import { useApp } from "context";
import brelly from "@brelly/sdk";
import { format } from 'date-fns';
import { BrellyBtn } from "components/Button";
import { InlineModal } from "components/Modals";
import ImgTitleSub from "components/ImgTitleSub";
import { useState, useEffect, useRef } from "react";
import { IoChevronDownOutline } from "react-icons/io5";
import { InviteTeamMemberModal } from 'modals';

export const OrgTeamSection = ({ orgTab }) => {
  const { orgId, setLoading, setError, addToast, selectedMembership } = useApp();
  const [team, setTeam] = useState([]);
  const [invites, setInvites] = useState([]);
  const isSelected = orgTab === "3";
  const [addModal, setAddModal] = useState(false);
  const role = selectedMembership?.role;
  const canEdit = role === 'ADMIN' || role === 'OWNER'

  const handleTeam = async () => {
    if (orgId) {
      try {
        let { data } = await brelly.organizations.getMemberships(orgId);
        let data2 = await brelly.organizations.getPendingMembershipInvites(orgId);
        console.log(data2);
        if (Array.isArray(data)) {
          setTeam(data);
        }
        // setInvites(data2)
      } catch (err) {
        setError(err?.message)
      }
    }
  };

  useEffect(() => {
    if (isSelected) {
      handleTeam();
    }
    //eslint-disable-next-line
  }, [isSelected, orgId]);

  const handleInviteResend = async (invite) => {
    try {
      setLoading(true);
      await brelly.membershipInvites.resend(invite?.id);
      addToast("Invite Resent")
      setLoading(false);
    } catch(err) {
      setError(err?.message)
      setLoading(false);
    }
  }

  const handleInviteCancel = async (invite) => {
    try {
      setLoading(true);
      await brelly.membershipInvites.cancel(invite?.id);
      handleTeam()
      setLoading(false);
    } catch(err) {
      setError(err?.message)
      setLoading(false);
    }
  }

  return (
    <div
      style={{
        width: "100%",
        paddingTop: 22,
        display: isSelected ? "" : "none",
        fontFamily: 'Lato',
      }}
    >
      <div
        className="dash-box-title jb-ac"
        style={{ color: "black", marginBottom: 24 }}
      >
        Team
        {canEdit && <div onClick={() => setAddModal(true)} className='brelly-text-btn'>Add New</div>}
      </div>
      <div className="jb-ac mb-2">
        <h3 style={{ fontSize: 15, color: "#64666B", fontWeight: 700 }}>
          User
        </h3>
        <div style={{ width: canEdit ? 200 : '' }}>
          <h3 style={{ fontSize: 15, color: "#64666B", fontWeight: 700 }}>
            Role
          </h3>
        </div>
      </div>

      {team?.map((tm, key) => (
        <div className="jb-ac mb-3" key={key}>
          <ImgTitleSub
            img={tm?.user?.profilePictureUrl}
            title={`${tm?.user?.firstName} ${tm?.user?.lastName}`}
            sub={tm?.user?.email}
          />
          {canEdit && <MemberRoleBtn member={tm} />}
          {!canEdit && <div>{tm?.role}</div>}
        </div>
      ))}

      {(canEdit && invites?.length > 0) && (
        <h3 style={{ fontSize: 15, color: "#64666B", fontWeight: 700 }} className='mt-5 mb-2'>
          Pending Invites
        </h3>
      )}

      {canEdit && invites?.map((invite, idx) => (
        <div className="row mt-1 pt-1 js-ac" key={invite?.id} style={{ borderTop: idx === 0 ? '' : '1px solid rgba(0,0,0,.12)' }}>
          <div style={{ fontWeight: 600 }} className="col-6 js-ac">{invite?.email}</div>
          <div style={{ fontSize: 12 }} className="col-3 js-ac">{format(new Date(invite?.lastSentAt), 'MM/dd/yyyy')}</div>
          <div className="col-3 je-ac">
            <div onClick={() => handleInviteResend(invite)} className='brelly-text-btn me-3'>Resend</div>
            <div onClick={() => handleInviteCancel(invite)} className='brelly-text-btn'>Cancel</div>
          </div>
        </div>
      ))}

      <InviteTeamMemberModal open={addModal} handleClose={() => setAddModal(false)} handleTeam={handleTeam} />
    </div>
  );
};

const MemberRoleBtn = ({ member }) => {
  const { setLoading, setError } = useApp()
  const [editModal, setEditModal] = useState(null);
  const modalBtnRef = useRef(null);
  const canChange = member?.role !== 'OWNER';

  const handleChange = async (newRole, membership) => {
    setEditModal(false);
    try {
      setLoading(true);
      await brelly.memberships.update(membership?.id, { role: newRole.toUpperCase() });
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err?.message);
    }
  };

  const handleModal = () => {
    if (canChange) {
      setEditModal(!editModal)
    }
  }

  return (
    <div style={{ width: 200 }}>
      <BrellyBtn onClick={handleModal} btnRef={modalBtnRef}>
        <div className="jc-ac">
          <div>{member?.role}</div>
          {canChange && <IoChevronDownOutline style={{ color: "white", marginLeft: 2 }} />}
        </div>
      </BrellyBtn>

      <InlineModal
        btnRef={modalBtnRef}
        open={editModal}
        handleClose={() => setEditModal(false)}
      >
        <BrellyBtn onClick={() => handleChange("Member")} className="mb-3">
          Member
        </BrellyBtn>
        <BrellyBtn onClick={() => handleChange("Admin")} className="mb-3">
          Admin
        </BrellyBtn>
      </InlineModal>
    </div>
  );
};
